import { useCallback } from 'react';
import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { useGetPayment } from '../../get-payment';
import { ApiErrorType } from '../../common/api';
import { useGlobalContext } from '../../globalContext';
import { useCompletePayment } from './useCompletePayment';

export interface UseValidatePaymentResult {
  validatePayment: (paymentId: string) => Promise<void>;
}
/* Hook to check if check if payment is succeeded or not - for 3DS and APMs */
export const useValidatePayment = (): UseValidatePaymentResult => {
  const { setAuthorizationUrl, setIsLoading, options } = useGlobalContext();
  const { completePayment } = useCompletePayment();

  const apiKey = options?.apiKey;

  const { handleGetPayment } = useGetPayment(apiKey);

  const validatePayment = useCallback(
    async (id: string): Promise<void> => {
      setIsLoading(true); // show loading state on pay button
      setAuthorizationUrl(undefined); // show card form again

      if (!apiKey) {
        const typedError: TypedError = { type: 'apiKeyRequired', message: 'Client token is required.' };

        const result = {
          errors: [typedError],
        };
        completePayment(result);
        return;
      }

      try {
        const result = await handleGetPayment(id);

        completePayment(result);
      } catch (error) {
        const typedError = convertErrorToTypedError(ApiErrorType.Authorize, error);
        const result = {
          errors: [typedError],
        };
        completePayment(result);
      } finally {
        setIsLoading(false);
      }
    },
    [apiKey, setIsLoading, setAuthorizationUrl, handleGetPayment, completePayment]
  );

  return {
    validatePayment,
  };
};

export default useValidatePayment;
