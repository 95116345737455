import { PaymentMethod, PaymentMethodType } from '@whenthen/sdk-authorize';

import { TypedError, GooglePayPaymentRequestPayload } from '@whenthen/checkout-sdk-core';
import { CheckoutSdkFrameEventType } from '@whenthen/checkout-sdk-hosted-core';
import { useHandlePay } from '../../pay';
import { useAuthorizePayment } from '../../authorize-payment';
import { useGlobalContext } from '../../globalContext';
import { useProxyParams } from '../../proxy';
import { PaymentsClientResult, PaymentDataRequest } from '../utils';

export interface UseHandleGooglePayResult {
  handleGooglePayAction: ({ paymentsClient, paymentDataRequest }: HandleGooglePayParams) => Promise<void>;
  handleDispatchUnknownError: (error?: string) => void;
}
export interface HandleGooglePayParams {
  paymentsClient: PaymentsClientResult;
  paymentDataRequest: PaymentDataRequest;
  transactionId: string;
}

export interface LoadPaymentDataError {
  statusMessage: string;
}

export const useHandleGooglePay = (): UseHandleGooglePayResult => {
  const { options, proxyRef } = useGlobalContext();
  const { pay } = useHandlePay();
  const { isDropIn } = useProxyParams();
  const { authorizePayment } = useAuthorizePayment(options?.apiKey, isDropIn);

  const handleDispatchUnknownError = (message?: string): void => {
    const typedError: TypedError = { type: CheckoutSdkFrameEventType.Error, message: message || 'Unknown error' };
    const errors = [typedError];

    if (!isDropIn) {
      /* When user closes the google pay dialog, Google throws an error with undefined message - For elements, developer will trigger google dialog with our exposed method `getGooglePayToken`, which is a promise, here pass the error back to sdk-loader, so it can resolve that promise with this payload */
      proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.GetGooglePayToken, { errors });
      return;
    }

    proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.Error, { errors });
  };

  // triggers the dialog when all data are computed and handles the token based on  sdk type
  const handleGooglePayAction = async ({
    paymentsClient,
    paymentDataRequest,
    transactionId,
  }: HandleGooglePayParams): Promise<void> => {
    try {
      const paymentData: GooglePayPaymentRequestPayload = await paymentsClient?.loadPaymentData(paymentDataRequest);

      const walletToken = paymentData?.paymentMethodData?.tokenizationData?.token;

      if (!walletToken) {
        handleDispatchUnknownError('No wallet token was returned from Google Pay');
        return;
      }

      /* For elements, developer will trigger google dialog with oue exposed method `getGooglePayToken`, which is a promise, here we pass the response back to sdk-load, so it can resolve that promise with this payload */
      if (!isDropIn) {
        proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.GetGooglePayToken, {
          data: paymentData,
        });
        return;
      }

      await pay({
        handleAuthorizePayment: authorizePayment,
        paymentMethodType: PaymentMethod.GOOGLE_PAY as PaymentMethodType,
        walletToken,
        transactionId,
      });
    } catch (error) {
      handleDispatchUnknownError((error as LoadPaymentDataError)?.statusMessage);
    }
  };
  return {
    handleGooglePayAction,
    handleDispatchUnknownError,
  };
};

export default useHandleGooglePay;
