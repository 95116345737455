import {
  SdkVault,
  CreateCustomerInput,
  CreateCustomerResult as CreateCustomerApiResult,
  CustomerInput,
} from '@whenthen/sdk-vault';
import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { CreateCustomerResult } from './create-customer-result';
import { ApiErrorType } from '../../common/api';

export const createCustomer = async (apiKey: string, customer: CustomerInput): Promise<CreateCustomerResult> => {
  const vault = new SdkVault({ apiKey });

  const createCustomerData: CreateCustomerInput = {
    data: {
      customer,
    },
  };

  let response: CreateCustomerApiResult;

  try {
    response = await vault.createCustomer(createCustomerData);
  } catch (error) {
    const typedError = convertErrorToTypedError(ApiErrorType.Vault, error);

    return {
      errors: [typedError],
    };
  }

  const errors: TypedError[] | undefined = response.errors?.map((error) => ({
    type: ApiErrorType.Vault,
    message: error.message,
  }));

  return {
    customerId: response?.data?.id,
    errors,
  };
};

export default createCustomer;
