/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  GooglePayCardAuthMethod,
  GooglePayPaymentMethodOptions,
  GooglePayMerchantInfo,
  GooglePayTransactionInfo,
  GooglePayButtonConfig,
  GooglePayButtonColor,
  GooglePayButtonType,
  GooglePayCardParameters,
  GooglePayPaymentData,
  GooglePayEnvironment,
} from '@whenthen/checkout-sdk-elements-core';
import { getGooglePayEnvironment } from './get-googlepay-environment';

const defaultAllowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'MIR', 'VISA'];

const defaultAllowedAuthMethods = [GooglePayCardAuthMethod.PAN_ONLY, GooglePayCardAuthMethod.CRYPTOGRAM_3DS];

const defaultCardParameters = {
  allowedAuthMethods: defaultAllowedAuthMethods,
  allowedCardNetworks: defaultAllowedCardNetworks,
  allowPrepaidCards: true,
  allowCreditCards: true,
  billingAddressRequired: false,
  billingAddressParameters: undefined,
};

const defaultPaymentData = {
  emailRequired: false,
  shippingAddressRequired: false,
  shippingOptionRequired: false,
  shippingAddressParameters: undefined,
  shippingOptionParameters: undefined,
};

const defaultButtonConfig = {
  buttonSizeMode: 'fill',
  buttonColor: 'black' as GooglePayButtonColor,
  buttonType: 'buy' as GooglePayButtonType,
  buttonLocale: undefined,
};

export interface DefaultGooglePayButtonConfig extends GooglePayButtonConfig {
  buttonSizeMode: string;
}

export interface DefaultConfigOption
  extends Omit<GooglePayPaymentMethodOptions, 'merchantInfo' | 'transactionInfo' | 'merchantGatewayId'> {
  environment: GooglePayEnvironment;
  transactionInfo?: GooglePayTransactionInfo;
  button: DefaultGooglePayButtonConfig;
  merchantInfo?: GooglePayMerchantInfo;
  merchantGatewayId?: string;
}

export const defaultGooglePayConfig: DefaultConfigOption = {
  environment: getGooglePayEnvironment(),
  merchantInfo: undefined,
  transactionInfo: undefined,
  cardParameters: defaultCardParameters,
  existingPaymentMethodRequired: true,
  paymentData: defaultPaymentData,
  button: defaultButtonConfig,
};

export const mergeGooglePayConfig = (defaultConfig: DefaultConfigOption, config: GooglePayPaymentMethodOptions) => ({
  environment: defaultConfig.environment,
  merchantInfo: {
    ...defaultConfig.merchantInfo,
    ...(config?.merchantInfo || {}),
  },
  transactionInfo: {
    ...defaultConfig.transactionInfo,
    ...(config?.transactionInfo || {}),
  },
  cardParameters: {
    ...defaultConfig.cardParameters,
    ...(config?.cardParameters || {}),
  } as GooglePayCardParameters,
  existingPaymentMethodRequired: config?.existingPaymentMethodRequired || defaultConfig.existingPaymentMethodRequired,
  paymentData: {
    ...defaultConfig.paymentData,
    ...(config?.paymentData || {}),
  } as GooglePayPaymentData,
  button: {
    ...defaultConfig.button,
    ...(config?.button || {}),
  } as GooglePayButtonConfig,
});

export default defaultGooglePayConfig;
