import React, { FunctionComponent, useCallback, useState } from 'react';
import { AuthorizeIframe, AuthorizeFrameLoadingState } from '@whenthen/checkout-sdk-react-elements';
import { useGlobalContext } from '../globalContext';
import { useAuthorizeListener } from './hooks';
import Spinner from '../assets/img/Spinner.png';

const frameTitle = 'wt-auth-frame';

export const AuthorizeFrame: FunctionComponent = () => {
  const [loading, setIsLoading] = useState<boolean>(true);

  const handleLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const { authorizationUrl } = useGlobalContext();
  useAuthorizeListener();

  return (
    <>
      {loading && <AuthorizeFrameLoadingState imageUrl={Spinner} />}
      <AuthorizeIframe frameSrc={authorizationUrl} title={frameTitle} handleLoad={handleLoad} />
    </>
  );
};

export default AuthorizeFrame;
