import { IntentResult, SdkIntent } from '@whenthen/sdk-intent';
import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { UpdateIntentStatusApiResult } from './update-intent-status-api-result';
import { ApiErrorType } from '../common/api';

export const updateIntentStatus = async (intent: SdkIntent): Promise<UpdateIntentStatusApiResult> => {
  if (!intent.isIntentStarted) {
    const typedError: TypedError = { type: ApiErrorType.Intent, message: 'Intent is not started.' };

    return {
      errors: [typedError],
    };
  }

  let response: IntentResult;
  try {
    response = await intent.updateIntent({
      billing: {
        status: 'IN_PROGRESS',
      },
    });
  } catch (error) {
    const typedError = convertErrorToTypedError(ApiErrorType.Intent, error);

    return {
      errors: [typedError],
    };
  }

  const errors: TypedError[] | undefined = response.errors?.map((error) => ({
    type: ApiErrorType.Vault,
    message: error.message,
  }));

  return {
    data: response.data,
    errors,
  };
};

export default updateIntentStatus;
