export interface TokenizationSpecification {
  type: 'PAYMENT_GATEWAY';
  parameters: {
    gateway: string;
    gatewayMerchantId: string;
  };
}

/* https://developers.google.com/pay/api/web/guides/tutorial */
export const generateTokenizationSpecification = (merchantId: string): TokenizationSpecification => ({
  type: 'PAYMENT_GATEWAY',
  parameters: {
    gateway: 'whenthen',
    gatewayMerchantId: merchantId,
  },
});
