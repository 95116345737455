import { ErrorOutput } from '@whenthen/sdk-authorize';
import { AuthorizeErrorCode } from '@whenthen/checkout-sdk-core';

export interface HasHandleAuthorizeErrorResult {
  hasHandledAuthError: boolean;
  authUrl?: string;
  supportsIframe: boolean;
}

const handledAuthorizeErrorCodes = [AuthorizeErrorCode.Apm, AuthorizeErrorCode.ThreeDs];

export const getHasHandleAuthorizeError = (errors?: ErrorOutput[] | null): HasHandleAuthorizeErrorResult => {
  const errorObject = errors?.find(
    (error) =>
      error.extensions?.code && handledAuthorizeErrorCodes.includes(error.extensions?.code as AuthorizeErrorCode)
  );

  return {
    hasHandledAuthError: Boolean(errorObject),
    authUrl: errorObject?.extensions?.url,
    supportsIframe: Boolean(errorObject?.extensions?.supportsIframe),
  };
};
