// eslint-disable-next-line import/prefer-default-export
export const parseBoolean = (value?: string | null): boolean | null => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};
