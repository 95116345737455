import { CheckoutSdkFrameParam } from '@whenthen/checkout-sdk-hosted-core';

export enum AuthorizeRedirectUrlParamKeys {
  WhenThenFrameUrlKey = 'wt-frame-url',
  RedirectedFromAuthKey = 'wt-redirected-from-auth',
}

export interface GenerateAuthorizeFrameRedirectUrl {
  sourceOrigin: string;
  loaderVersion: string;
  sdkType: string;
  origin: string;
}

export const generateAuthorizeFrameRedirectUrl = ({
  sourceOrigin,
  loaderVersion,
  sdkType,
  origin,
}: GenerateAuthorizeFrameRedirectUrl): string => {
  if (!sourceOrigin || !loaderVersion || !sdkType) throw new Error('Required param is missing.');

  const url = new URL(window.origin);
  url.searchParams.set(CheckoutSdkFrameParam.Referrer, sourceOrigin);
  url.searchParams.set(CheckoutSdkFrameParam.Version, loaderVersion);
  url.searchParams.set(CheckoutSdkFrameParam.SdkType, sdkType);
  url.searchParams.set(AuthorizeRedirectUrlParamKeys.WhenThenFrameUrlKey, origin);
  url.searchParams.set(AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey, 'true');

  return url.toString();
};
