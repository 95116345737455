/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* @ts-ignore */

import {
  GooglePayMerchantInfo,
  GooglePayTransactionInfo,
  GooglePayShippingOptionParameters,
  GooglePayShippingAddressParameters,
  GooglePayPaymentData,
} from '@whenthen/checkout-sdk-elements-core';

import { CardPaymentMethod } from './generate-card-payment-method';
import { baseRequest, BaseRequest } from './base-request';

export interface MerchantInfoPayload extends GooglePayMerchantInfo {
  merchantOrigin: string;
}

export interface PaymentDataRequest extends BaseRequest {
  allowedPaymentMethods: CardPaymentMethod[];
  merchantInfo: MerchantInfoPayload;
  transactionInfo: GooglePayTransactionInfo;

  shippingAddressRequired?: boolean;
  emailRequired?: boolean;
  shippingOptionRequired?: boolean;
  shippingAddressParameters?: GooglePayShippingAddressParameters;
  shippingOptionParameters?: GooglePayShippingOptionParameters;
}

export interface GeneratePaymentDataRequestParams {
  cardPaymentMethod: CardPaymentMethod;
  merchantInfo: GooglePayMerchantInfo;
  transactionInfo: GooglePayTransactionInfo;
  sourceOrigin: string;
  paymentData?: GooglePayPaymentData;
}

export const generatePaymentDataRequest = ({
  cardPaymentMethod,
  transactionInfo,
  merchantInfo,
  paymentData,
  sourceOrigin,
}: GeneratePaymentDataRequestParams): PaymentDataRequest => ({
  ...baseRequest,
  allowedPaymentMethods: [cardPaymentMethod],
  transactionInfo,
  merchantInfo: {
    merchantName: merchantInfo.merchantName,
    merchantId: merchantInfo.merchantId,
    merchantOrigin: sourceOrigin,
  },
  shippingAddressRequired: paymentData?.shippingAddressRequired,
  emailRequired: paymentData?.emailRequired,
  shippingOptionRequired: paymentData?.shippingOptionRequired,
  shippingAddressParameters: paymentData?.shippingAddressParameters,
  shippingOptionParameters: paymentData?.shippingOptionParameters,
});
