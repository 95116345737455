import {
  CardPaymentMethodInput,
  GooglePayPaymentMethodInput,
  ApplePayPaymentMethodInput,
  BasePaymentMethodInput,
  PaymentMethodInput,
  PaymentMethodType,
  PaymentMethod,
} from '@whenthen/sdk-authorize';

export interface GetPaymentMethodParam {
  paymentMethodType: PaymentMethodType;
  token?: string;
  walletToken?: string;
  transactionId?: string;
}

export const composePaymentMethodInput = ({
  paymentMethodType,
  token,
  walletToken,
  transactionId,
}: GetPaymentMethodParam): PaymentMethodInput => {
  switch (paymentMethodType) {
    case PaymentMethod.CARD:
      return { type: paymentMethodType, token } as CardPaymentMethodInput;

    case PaymentMethod.GOOGLE_PAY:
      return {
        type: paymentMethodType,
        walletToken,
        googlePay: {
          transactionId,
        },
      } as GooglePayPaymentMethodInput;

    case PaymentMethod.APPLE_PAY:
      return {
        type: paymentMethodType,
        walletToken,
      } as ApplePayPaymentMethodInput;

    default:
      return { type: paymentMethodType } as BasePaymentMethodInput;
  }
};
