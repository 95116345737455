import { useCallback } from 'react';
import { WhenThenAuthorize, ErrorOutput } from '@whenthen/sdk-authorize';
import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { ApiErrorType } from '../common/api';
import { GetPaymentResult } from './get-payment-result';

export interface UseGetPaymentResult {
  handleGetPayment: (id: string) => Promise<GetPaymentResult>;
}

export const useGetPayment = (apiKey?: string): UseGetPaymentResult => {
  const handleGetPayment = useCallback(
    async (id: string) => {
      if (!apiKey) {
        const typedError: TypedError = { type: 'apiKeyRequired', message: 'Client token is required.' };

        return {
          errors: [typedError],
        };
      }

      const whenthenAuthorize = WhenThenAuthorize({ apiKey });

      try {
        const getPaymentResult = await whenthenAuthorize.getPayment({ id });

        const errors: TypedError[] | undefined = getPaymentResult.errors?.length
          ? getPaymentResult.errors.map((error: ErrorOutput) =>
              convertErrorToTypedError(ApiErrorType.Authorize, error)
            ) ?? []
          : undefined;

        return {
          data: getPaymentResult.data,
          errors,
        };
      } catch (error) {
        const typedError: TypedError = convertErrorToTypedError(ApiErrorType.GetPayment, error);

        return {
          errors: [typedError],
        };
      }
    },
    [apiKey]
  );

  return {
    handleGetPayment,
  };
};
