import { useCallback } from 'react';
import {
  AuthorizePaymentInput,
  AuthorizePaymentResult,
  PaymentMethodType,
  PaymentMethod,
} from '@whenthen/sdk-authorize';
import { TypedError, convertErrorToTypedError } from '@whenthen/checkout-sdk-core';
import { CheckoutSdkDropInOptions } from '@whenthen/checkout-sdk-elements-core';
import { CheckoutSdkFrameEventType } from '@whenthen/checkout-sdk-hosted-core';
import { generateAuthorizeFrameRedirectUrl, useCompletePayment } from '../authorize-payment';
import { useProxyParams } from '../proxy';
import {
  getHasHandleAuthorizeError,
  HasHandleAuthorizeErrorResult,
  findMissingOptions,
} from '../authorize-payment/utils';
import { useGlobalContext } from '../globalContext';
import { TokenisePaymentMethodResult } from '../vault/tokenise-payment-method';
import { ApiErrorType } from '../common/api';
import { composePaymentMethodInput } from './compose-payment-method-input';

export interface UseHandlePayResult {
  pay: (payParams: PayParams) => Promise<void>;
}
export interface PayParams {
  handleTokenizeCard?: () => Promise<TokenisePaymentMethodResult>;
  handleAuthorizePayment: (authorizePaymentInput: AuthorizePaymentInput) => Promise<AuthorizePaymentResult>;
  paymentMethodType: PaymentMethodType;
  walletToken?: string;
  transactionId?: string;
}
export const useHandlePay = (): UseHandlePayResult => {
  const { sourceOrigin, loaderVersion, sdkType, isDropIn } = useProxyParams();
  const { setAuthorizationUrl, proxyRef, setIsLoading, options } = useGlobalContext();
  const { completePayment } = useCompletePayment();

  const pay = useCallback(
    async ({
      handleTokenizeCard,
      handleAuthorizePayment,
      paymentMethodType,
      walletToken,
      transactionId,
    }: PayParams) => {
      setIsLoading(true);

      try {
        const { amount, flowId, intentId, currencyCode, orderId, customerId, fraud } =
          options as CheckoutSdkDropInOptions;
        // validate params based on payment method
        const { missingOptions, hasMissingOptions } = findMissingOptions(
          paymentMethodType,
          options as CheckoutSdkDropInOptions
        );
        if (hasMissingOptions) {
          const typedError: TypedError = {
            type: 'error',
            message: `Required param is missing - ${missingOptions.join(', ')}`,
          };
          proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.Error, { errors: [typedError] });
          return;
        }

        let tokenisePaymentMethodResult = null;
        // tokenize + handle errors and response
        if (paymentMethodType === PaymentMethod.CARD && typeof handleTokenizeCard === 'function') {
          const tokenizeResult = await handleTokenizeCard();
          if (!tokenizeResult?.token && tokenizeResult.errors?.length) {
            completePayment({
              errors: tokenizeResult.errors,
            });
            return;
          }
          tokenisePaymentMethodResult = tokenizeResult;
        }

        // generate redirectUrl
        const redirectUrl = generateAuthorizeFrameRedirectUrl({
          sourceOrigin,
          loaderVersion,
          sdkType,
          origin: window.origin,
        });

        // authorize + handle errors and response
        const authorizeResult = await handleAuthorizePayment({
          authorizePayment: {
            flowId,
            orderId,
            intentId,
            amount,
            currencyCode,
            paymentMethod: composePaymentMethodInput({
              paymentMethodType,
              token: tokenisePaymentMethodResult?.token,
              walletToken,
              transactionId,
            }),
            perform3DSecure: redirectUrl ? { redirectUrl } : undefined,
            ...(paymentMethodType !== PaymentMethod.CARD && { apmRedirectUrl: redirectUrl }),
            customer: {
              id: customerId,
            },
            fraud,
          },
        });

        const { hasHandledAuthError, authUrl, supportsIframe }: HasHandleAuthorizeErrorResult =
          getHasHandleAuthorizeError(authorizeResult?.errors);

        /* Handle 3DS and move on tho the end */
        if (authorizeResult.errors?.length && hasHandledAuthError && isDropIn && typeof authUrl === 'string') {
          if (supportsIframe) {
            // open 3ds frame
            setAuthorizationUrl(authUrl);
            return;
          }
          // send to main frame
          proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.OpenHostAuthPopup, {
            url: authUrl,
          });
          return;
        }

        // has error but not handled || succeeded
        const { data, errors } = authorizeResult;
        const typedErrors: TypedError[] | undefined =
          errors && errors?.length
            ? errors.map((error) => convertErrorToTypedError(ApiErrorType.Authorize, error)) ?? []
            : undefined;

        completePayment({
          data,
          errors: typedErrors,
        });
        return;
      } catch (error) {
        const typedError = convertErrorToTypedError(CheckoutSdkFrameEventType.Error, error);

        const errors = [typedError];

        proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.Error, { errors });
      } finally {
        setIsLoading(false);
      }
    },
    [options, sourceOrigin, loaderVersion, sdkType, setIsLoading]
  );

  return {
    pay,
  };
};
