/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { GooglePayPaymentMethodOptions, AlternativePaymentMethod } from '@whenthen/checkout-sdk-elements-core';
import { TypedError } from '@whenthen/checkout-sdk-core';
import { CheckoutSdkFrameEventType } from '@whenthen/checkout-sdk-hosted-core';

import {
  baseRequest,
  generateBaseCardPaymentMethod,
  generateCardPaymentMethod,
  mergeGooglePayConfig,
  defaultGooglePayConfig,
  generatePaymentDataRequest,
  checkIsGooglePayLoaded,
  GoogleWindow,
  PaymentsClientResult,
  GoogleWindowResult,
  DefaultGooglePayButtonConfig,
} from '../utils';
import { useGlobalContext } from '../../globalContext';
import { useHandleGooglePay } from './use-handle-google-pay-action';
import { useProxyParams } from '../../proxy';
import { LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE } from './constant';

export interface UseGooglePayResult {
  handleGooglePay: () => Promise<void>;
}

/* See react-element sdk */
const containerId = 'google_button_container';

export const useGooglePay = (): UseGooglePayResult => {
  const { options, proxyRef, isCustomPayButton } = useGlobalContext();
  const { handleGooglePayAction, handleDispatchUnknownError } = useHandleGooglePay();
  const { sourceOrigin, isDropIn } = useProxyParams();

  // find googlePay config
  const googlePayOption = options?.alternativePaymentMethods?.find(
    (option: AlternativePaymentMethod) => option.type === 'googlepay'
  )?.options as GooglePayPaymentMethodOptions;

  // merge default config with user config
  const googlePayConfig = mergeGooglePayConfig(defaultGooglePayConfig, googlePayOption);
  // generate base card payment method
  const baseCardPaymentMethod = generateBaseCardPaymentMethod(googlePayConfig.cardParameters);

  // generate  card payment method with baseCardPaymentMethod
  const cardPaymentMethod = generateCardPaymentMethod(baseCardPaymentMethod, googlePayConfig.merchantInfo?.merchantId);

  // generate payment data request
  const paymentDataRequest = generatePaymentDataRequest({
    cardPaymentMethod,
    transactionInfo: googlePayConfig.transactionInfo,
    merchantInfo: googlePayConfig.merchantInfo,
    paymentData: googlePayConfig.paymentData,
    sourceOrigin,
  });

  const handleGooglePay = async (): Promise<void> => {
    if (!checkIsGooglePayLoaded()) {
      handleDispatchUnknownError();
      return;
    }

    const buttonConfig = googlePayConfig.button as DefaultGooglePayButtonConfig;

    try {
      const google: GoogleWindowResult =
        (window as unknown as GoogleWindow)?.google || (global as unknown as GoogleWindow)?.google;

      // configure environment
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */
      /* @ts-ignore */
      const paymentsClient: PaymentsClientResult = new google.payments.api.PaymentsClient({
        environment: googlePayConfig?.environment,
      });

      const isReadyToPayPayload = {
        ...baseRequest,
        allowedPaymentMethods: [baseCardPaymentMethod],
      };

      const isReadyResponse = await paymentsClient.isReadyToPay(isReadyToPayPayload);

      const isReadyToPay = isReadyResponse?.result;

      if (!isReadyToPay) return;

      const { transactionId } = googlePayConfig.transactionInfo;

      // if it's elements or custom pay button drop in, trigger google pop-up directly
      if (!isDropIn || (isDropIn && isCustomPayButton)) {
        await handleGooglePayAction({ paymentsClient, paymentDataRequest, transactionId });
        return;
      }

      // create button
      const button = paymentsClient.createButton({
        onClick: (): Promise<void> => handleGooglePayAction({ paymentsClient, paymentDataRequest, transactionId }),
        allowedPaymentMethods: [cardPaymentMethod],
        buttonSizeMode: buttonConfig.buttonSizeMode,
        buttonColor: buttonConfig.buttonColor,
        buttonType: buttonConfig.buttonType,
        buttonLocale: buttonConfig.buttonLocale,
      });

      const container = document.getElementById(containerId);

      if (buttonConfig.buttonColor === 'white' && container) {
        container.style.padding = '2px';
      }

      // add a Google Pay payment button too dom
      container?.appendChild(button);
    } catch {
      const typedError: TypedError = {
        type: CheckoutSdkFrameEventType.Error,
        message: LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE,
      };
      const errors = [typedError];

      proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.Error, { errors });
    }
  };

  return { handleGooglePay };
};

export default useGooglePay;
