import { PaymentMethod, PaymentMethodType } from '@whenthen/sdk-authorize';
import { CheckoutSdkDropInOptions, SdkOptions } from '@whenthen/checkout-sdk-elements-core';

const baseSdkOptions = [SdkOptions.Amount, SdkOptions.FlowId, SdkOptions.CurrencyCode];

const paymentMethodSdkOptions = {
  [PaymentMethod.APPLE_PAY]: baseSdkOptions, // apple pay is authorize on parent app - see sdk-loader
  [PaymentMethod.KLARNA]: [...baseSdkOptions, SdkOptions.CustomerId],
  [PaymentMethod.CARD]: baseSdkOptions,
  [PaymentMethod.PAYPAL]: baseSdkOptions,
  [PaymentMethod.GOOGLE_PAY]: baseSdkOptions,
};

export interface FindMissingOptionsResult {
  missingOptions: SdkOptions[];
  hasMissingOptions: boolean;
}

export const findMissingOptions = (
  paymentMethodType: PaymentMethodType,
  options?: CheckoutSdkDropInOptions
): FindMissingOptionsResult => {
  if (!paymentMethodType) throw new Error('Missing payment method type');

  const requiredOptions = paymentMethodSdkOptions[paymentMethodType];

  if (!requiredOptions) throw new Error(`Required params missing for payment method${paymentMethodType}`);

  const missingOptions = requiredOptions.filter((option: SdkOptions) => {
    if (options as CheckoutSdkDropInOptions) {
      return !options?.[option];
    }
    return true;
  });

  const hasMissingOptions = missingOptions.length > 0;

  return {
    missingOptions,
    hasMissingOptions,
  };
};
