import {
  SdkVault,
  AddressInput,
  TokenisePaymentMethodInput,
  VaultCustomerInput,
  TokenisePaymentMethodResult,
} from '@whenthen/sdk-vault';
import {
  convertErrorToTypedError,
  DeepRequired,
  parseCardExpiry,
  parseCardNumber,
  TypedError,
} from '@whenthen/checkout-sdk-core';
import { Card, BillingAddress } from '@whenthen/checkout-sdk-elements-core';
import { TokenisePaymentMethodApiResult } from './tokenise-payment-method-api-result';
import { ApiErrorType } from '../../common/api';

export const tokenisePaymentMethod = async (
  apiKey: string,
  card: DeepRequired<Card>,
  billingAddress: DeepRequired<BillingAddress> | undefined,
  idempotencyKey: string,
  customerId?: string,
  isSaveCard = false
): Promise<TokenisePaymentMethodApiResult> => {
  const cardNumber = parseCardNumber(card.cardNumber ?? '');
  const expiry = parseCardExpiry(card.cardExpiry ?? '');

  const vault = new SdkVault({ apiKey });
  let customerInput: VaultCustomerInput | undefined;
  if (isSaveCard || customerId) {
    customerInput = customerId ? { id: customerId } : { name: card.billingName ?? '' };
  } else {
    customerInput = undefined;
  }

  const billingAddressInput: AddressInput | undefined =
    billingAddress && !billingAddress.isSameBillingAddress
      ? {
          country: billingAddress.country,
          postalCode: billingAddress.zip,
        }
      : undefined;

  const data: TokenisePaymentMethodInput = {
    data: {
      paymentMethod: {
        card: {
          number: cardNumber,
          name: card.billingName,
          expMonth: expiry.month,
          expYear: expiry.year,
          cvc: card.cardCvc,
          billingAddress: billingAddressInput,
        },
      },
      customer: customerInput,
    },
  };

  let response: TokenisePaymentMethodResult;
  try {
    response = await vault.tokenisePaymentMethod(data, idempotencyKey);
  } catch (error) {
    const typedError = convertErrorToTypedError(ApiErrorType.Vault, error);

    return {
      errors: [typedError],
    };
  }

  const errors: TypedError[] | undefined = response.errors?.map((error) => ({
    type: ApiErrorType.Vault,
    message: error.message,
  }));

  return {
    token: response.data?.token,
    customerId: response.data?.customer?.id ?? customerId,
    errors,
  };
};

export default tokenisePaymentMethod;
