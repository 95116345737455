import { useCallback, useState } from 'react';
import { WhenThenAuthorize, AuthorizePaymentInput, ErrorOutput } from '@whenthen/sdk-authorize';
import { convertErrorToTypedError, generateUuid, TypedError } from '@whenthen/checkout-sdk-core';
import { ApiErrorType } from '../../common/api';
import { AuthorizePaymentResult } from '../authorize-payment-result';

export interface UseAuthorizePaymentResult {
  authorizePayment: (
    authorizePaymentInput: AuthorizePaymentInput
  ) => Promise<AuthorizePaymentResult<TypedError[] | ErrorOutput[]>>;
  resetIdempotencyKey: () => void;
}

/* Hook returns  handleAuthorize to make authorize api call */
export const useAuthorizePayment = (apiKey?: string, isDropIn?: boolean): UseAuthorizePaymentResult => {
  const [authorizeIdempotencyKey, setIdempotencyKey] = useState<string | undefined>();
  const handleResetIdempotencyKey = useCallback(() => {
    setIdempotencyKey(undefined);
  }, []);

  const handleAuthorize = useCallback(
    async (authorizePaymentInput: AuthorizePaymentInput) => {
      if (!apiKey) {
        const typedError: TypedError = { type: 'apiKeyRequired', message: 'Client token is required.' };

        return {
          errors: [typedError],
        } as AuthorizePaymentResult<TypedError[]>;
      }

      const whenthenAuthorize = WhenThenAuthorize({ apiKey });

      let authorizePaymentIdempotencyKey = authorizeIdempotencyKey;
      if (!authorizePaymentIdempotencyKey) {
        authorizePaymentIdempotencyKey = generateUuid();
        setIdempotencyKey(authorizePaymentIdempotencyKey);
      }

      try {
        const authorizePaymentResult = await whenthenAuthorize.authorizePayment(
          authorizePaymentInput,
          authorizePaymentIdempotencyKey
        );

        const errors: TypedError[] | undefined = authorizePaymentResult.errors?.length
          ? authorizePaymentResult.errors.map((error: ErrorOutput) =>
              convertErrorToTypedError(ApiErrorType.Authorize, error)
            ) ?? []
          : undefined;

        return {
          data: authorizePaymentResult.data,
          errors: isDropIn ? authorizePaymentResult.errors : errors,
        };
      } catch (error) {
        const typedError: TypedError = convertErrorToTypedError(ApiErrorType.Authorize, error);

        return {
          errors: [typedError],
        };
      }
    },
    [apiKey, authorizeIdempotencyKey, isDropIn]
  );

  return {
    authorizePayment: handleAuthorize,
    resetIdempotencyKey: handleResetIdempotencyKey,
  };
};
