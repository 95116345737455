import React, { FunctionComponent, useState, useMemo, useRef } from 'react';
import {
  CheckoutSdkOptions,
  CheckoutPaymentMethodType,
  CheckoutSdkDropInOptions,
  PayButtonType,
} from '@whenthen/checkout-sdk-elements-core';
import { CheckoutSdk } from './checkout-sdk/CheckoutSdk';
import { AuthorizeFrame } from './authorize-payment/AuthorizeFrame';
import { GlobalContextProvider, GlobalContextValues } from './globalContext';
import { CheckoutSdkFrameToSdkProxy, useProxyParams } from './proxy';
import { useAuthorizePayment } from './authorize-payment';

export const App: FunctionComponent = () => {
  const [authorizationUrl, setAuthorizationUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessState, setShowSuccessState] = useState<boolean>(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>();
  const proxyRef = useRef<CheckoutSdkFrameToSdkProxy>();
  const [options, setOptions] = useState<CheckoutSdkOptions | undefined>();
  const { isDropIn } = useProxyParams();
  const [activePaymentMethod, setActivePaymentMethod] = useState<CheckoutPaymentMethodType>('card');

  const { authorizePayment, resetIdempotencyKey: resetAuthorizeIdempotencyKey } = useAuthorizePayment(
    options?.apiKey,
    isDropIn
  );

  const isCustomPayButton = (options as CheckoutSdkDropInOptions)?.payButton === ('CUSTOM' as PayButtonType);

  const contextValues: GlobalContextValues = useMemo(
    () => ({
      authorizationUrl,
      setAuthorizationUrl,
      isLoading,
      setIsLoading,
      proxyRef,
      options,
      setOptions,
      showSuccessState,
      setShowSuccessState,
      paymentErrorMessage,
      setPaymentErrorMessage,
      resetAuthorizeIdempotencyKey,
      authorizePayment,
      activePaymentMethod,
      setActivePaymentMethod,
      isCustomPayButton,
    }),
    [
      authorizationUrl,
      setAuthorizationUrl,
      isLoading,
      setIsLoading,
      proxyRef,
      options,
      setOptions,
      showSuccessState,
      setShowSuccessState,
      paymentErrorMessage,
      setPaymentErrorMessage,
      resetAuthorizeIdempotencyKey,
      authorizePayment,
      activePaymentMethod,
      setActivePaymentMethod,
      isCustomPayButton,
    ]
  );

  return (
    <GlobalContextProvider value={contextValues}>
      <CheckoutSdk />
      {authorizationUrl && <AuthorizeFrame />}
    </GlobalContextProvider>
  );
};

App.propTypes = {};
App.defaultProps = {};

export default App;
