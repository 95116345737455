import { CheckoutSdkType } from '@whenthen/checkout-sdk-elements-core';
import { CheckoutSdkFrameParam } from '@whenthen/checkout-sdk-hosted-core';
import { env } from '../env';

export interface UseProxyParamsResult {
  sourceOrigin: string;
  loaderVersion: string;
  host: Window;
  sdkType: CheckoutSdkType;
  isDropIn: boolean;
}

export const useProxyParams = (): UseProxyParamsResult => {
  const params = new URLSearchParams(window.location.search);
  const sourceOrigin = params.get(CheckoutSdkFrameParam.Referrer);
  const loaderVersion = params.get(CheckoutSdkFrameParam.Version) ?? '0.0.0';
  const sdkType = params.get(CheckoutSdkFrameParam.SdkType) as CheckoutSdkType | undefined;
  const isDropIn = sdkType === CheckoutSdkType.DropIn;

  if (!sourceOrigin) {
    throw new Error(`SourceOrigin is required.`);
  }

  if (!sdkType) {
    throw new Error(`SDK Type is required.`);
  }

  if (loaderVersion && loaderVersion !== env.version) {
    // eslint-disable-next-line no-console
    console.warn(`Using "@whenthen/checkout-sdk-hosted@${env.version}" with "@whenthen/sdk-loader@${loaderVersion}".`);
  }

  return { sourceOrigin, loaderVersion, host: window, sdkType, isDropIn };
};

export default useProxyParams;
