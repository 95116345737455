import { SdkVault, GetPaymentMethodsInput, GetPaymentMethodsResult } from '@whenthen/sdk-vault';
import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { GetPaymentMethodsApiResult } from './get-payment-methods-api-result';
import { ApiErrorType } from '../../common/api';

export const getPaymentMethods = async (apiKey: string, customerId: string): Promise<GetPaymentMethodsApiResult> => {
  const vault = new SdkVault({ apiKey });

  const data: GetPaymentMethodsInput = {
    customerId,
  };

  let response: GetPaymentMethodsResult;
  try {
    response = await vault.getPaymentMethods(data);
  } catch (error) {
    return {
      errors: [convertErrorToTypedError(ApiErrorType.Vault, error)],
    };
  }

  const errors: TypedError[] | undefined = response.errors?.map((error) => ({
    type: ApiErrorType.Vault,
    message: error.message,
  }));

  const paymentMethods = response.data?.filter(Boolean);

  return {
    paymentMethods,
    errors,
  };
};

export default getPaymentMethods;
