import { TokenizationSpecification, generateTokenizationSpecification } from './tokenization-specification';

import { BasePaymentMethod } from './generate-base-payment-method';

export interface CardPaymentMethod extends BasePaymentMethod {
  tokenizationSpecification: TokenizationSpecification;
}

export const generateCardPaymentMethod = (
  baseCardPaymentMethod: BasePaymentMethod,
  merchantGatewayId?: string
): CardPaymentMethod => ({
  tokenizationSpecification: generateTokenizationSpecification(merchantGatewayId as string),
  ...baseCardPaymentMethod,
});
