import { useEffect, useCallback } from 'react';
import { GooglePayPaymentMethodOptions, AlternativePaymentMethod } from '@whenthen/checkout-sdk-elements-core';
import { TypedError } from '@whenthen/checkout-sdk-core';
import { CheckoutSdkFrameEventType } from '@whenthen/checkout-sdk-hosted-core';
import { checkIsGooglePayLoaded, googlePayScriptSrc } from '../utils';
import { useGlobalContext } from '../../globalContext';
import { LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE } from './constant';

export const useLoadGoogleScript = (): void => {
  const { options, proxyRef } = useGlobalContext();

  const googlePayOption =
    (options?.alternativePaymentMethods?.find((option: AlternativePaymentMethod) => option?.type === 'googlepay')
      ?.options as GooglePayPaymentMethodOptions) || undefined;

  const onScriptError = useCallback((): void => {
    const typedError: TypedError = {
      type: CheckoutSdkFrameEventType.Error,
      message: LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE,
    };
    const errors = [typedError];
    proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.Error, { errors });
  }, []);

  useEffect(() => {
    const isGooglePayLoaded = checkIsGooglePayLoaded();

    if (!googlePayOption || isGooglePayLoaded) return undefined;

    const script: HTMLScriptElement = document.createElement('script');
    script.src = googlePayScriptSrc;
    script.async = true;
    script.defer = true;
    // script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);
    document.body.appendChild(script);

    return () => {
      // script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
      document.body.removeChild(script);
    };
  }, [googlePayOption]);
};

export default useLoadGoogleScript;
