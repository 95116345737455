import { GooglePayCardParameters, GooglePayBillingAddressParameters } from '@whenthen/checkout-sdk-elements-core';

export interface BasePaymentMethod {
  type: 'CARD';
  parameters: {
    allowedAuthMethods?: string[];
    allowedCardNetworks?: string[];
    allowPrepaidCards?: boolean;
    allowCreditCards?: boolean;
    billingAddressRequired?: boolean;
    billingAddressParameters?: GooglePayBillingAddressParameters;
  };
}

export interface GenerateBaseCardPaymentMethodParam {
  allowedAuthMethods: string[];
  allowedCardNetworks: string[];
  allowPrepaidCards?: boolean;
  allowCreditCards?: boolean;
  billingAddressRequired?: boolean;
  billingAddressParameters?: GooglePayBillingAddressParameters;
}

export const generateBaseCardPaymentMethod = (cardParameters?: GooglePayCardParameters): BasePaymentMethod => ({
  type: 'CARD',
  parameters: {
    allowedAuthMethods: cardParameters?.allowedAuthMethods,
    allowedCardNetworks: cardParameters?.allowedCardNetworks,
    allowPrepaidCards: cardParameters?.allowPrepaidCards,
    allowCreditCards: cardParameters?.allowCreditCards,
    billingAddressRequired: cardParameters?.billingAddressRequired,
    billingAddressParameters: cardParameters?.billingAddressParameters,
  },
});
