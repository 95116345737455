import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  CheckoutContainer,
  CheckoutForm,
  CheckoutFormChange,
  SuccessState,
  FailedState,
  PAY_BUTTON_MAP,
} from '@whenthen/checkout-sdk-react-elements';
import { AuthorizePaymentInput, PaymentMethod, AuthorizeAlternativePaymentInput } from '@whenthen/sdk-authorize';
import {
  CheckoutSdkOptions,
  CheckoutSdkDropInOptions,
  BillingAddress,
  Card,
  CheckoutPaymentMethodType,
} from '@whenthen/checkout-sdk-elements-core';
import {
  TypedError,
  AuthorizePostMessagePayload,
  CheckHasAuthorizeErrorToHandleResult,
} from '@whenthen/checkout-sdk-core';
import { CheckoutSdkFrameEventType } from '@whenthen/checkout-sdk-hosted-core';
import { SdkIntent } from '@whenthen/sdk-intent';
import { CustomerInput } from '@whenthen/sdk-vault';
import { usePaymentMethods } from '../vault/payment-methods';
import { useTokenisePaymentMethod, TokenisePaymentMethodResult } from '../vault/tokenise-payment-method';
import { CheckoutFormState } from './checkout-form-state';
import { useProxyParams, CheckoutSdkFrameToSdkProxy } from '../proxy';
import {
  AuthorizePaymentResult,
  useValidatePayment,
  useAuthorizeListener,
  generateAuthorizeFrameRedirectUrl,
  useCompletePayment,
  ValidateAuthorizationResult,
  getHasHandleAuthorizeError,
  HasHandleAuthorizeErrorResult,
} from '../authorize-payment';
import { useHandlePay } from '../pay';
import { useGlobalContext } from '../globalContext';
import { useGooglePay, useLoadGoogleScript } from '../google-pay';
import { useCreateCustomer, CreateCustomerResult } from '../vault/create-customer';
import { useOptimise } from './hooks';

export const CheckoutSdk: FunctionComponent = () => {
  const [checkoutState, setCheckoutState] = useState<CheckoutFormState | undefined>(undefined);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [hostErrorMessage, setHostErrorMessage] = useState<string | undefined>();
  const { sourceOrigin, loaderVersion, sdkType, host, isDropIn } = useProxyParams();
  const {
    isLoading,
    authorizationUrl,
    proxyRef,
    options,
    setOptions,
    showSuccessState,
    paymentErrorMessage,
    setPaymentErrorMessage,
    authorizePayment,
    resetAuthorizeIdempotencyKey,
    setAuthorizationUrl,
    activePaymentMethod,
    setActivePaymentMethod,
    isCustomPayButton,
  } = useGlobalContext();

  const { validatePayment } = useValidatePayment();
  const { completePayment } = useCompletePayment();
  useLoadGoogleScript();
  const { handleGooglePay } = useGooglePay();
  useAuthorizeListener();
  const { tokenisePaymentMethod, resetIdempotencyKey: resetTokenizeIdempotencyKey } = useTokenisePaymentMethod(
    options?.apiKey
  );
  const { createCustomer } = useCreateCustomer(options?.apiKey);
  const handleTokenizeCard = useCallback(
    async (intent?: SdkIntent): Promise<TokenisePaymentMethodResult> => {
      if (!checkoutState || !options) {
        const typedError: TypedError = { type: 'notMounted', message: 'Element is not mounted.' };

        return {
          errors: [typedError],
        };
      }

      return tokenisePaymentMethod(checkoutState, options, intent);
    },
    [checkoutState, options, tokenisePaymentMethod]
  );

  const handleAuthorizePayment = useCallback(
    (authorizePaymentInput: AuthorizePaymentInput): Promise<AuthorizePaymentResult> =>
      authorizePayment(authorizePaymentInput),
    [authorizePayment]
  );

  const { paymentMethods } = usePaymentMethods({
    apiKey: options?.apiKey,
    customerId: options?.customerId,
  });

  const { pay } = useHandlePay();
  const handlePayWithCard = useCallback(
    () => pay({ handleTokenizeCard, handleAuthorizePayment, paymentMethodType: PaymentMethod.CARD }),
    [handleTokenizeCard, handleAuthorizePayment, pay]
  );

  // get optimised alternative payment methods if possible
  const { optimise } = useOptimise();

  const handleHostAuthComplete = useCallback(
    async (payload: AuthorizePostMessagePayload): Promise<void> => {
      await validatePayment(payload?.paymentId);
    },
    [validatePayment]
  );

  const handleCreateCustomer = useCallback(
    async (customerInput: CustomerInput): Promise<CreateCustomerResult> => createCustomer(customerInput),
    [createCustomer]
  );

  const handleAuthorizeApplePayComplete = useCallback(
    (result: AuthorizePaymentResult) => {
      // check if there is 3DS exception or APM exception
      const { hasHandledAuthError, authUrl, supportsIframe }: HasHandleAuthorizeErrorResult =
        getHasHandleAuthorizeError(result?.errors);

      if (result?.errors?.length && hasHandledAuthError && typeof authUrl === 'string') {
        if (supportsIframe) {
          // open 3ds frame
          setAuthorizationUrl(authUrl);
          return;
        }

        // send to main frame
        proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.OpenHostAuthPopup, {
          url: authUrl,
        });
        return;
      }

      completePayment(result as ValidateAuthorizationResult);
    },
    [completePayment, setAuthorizationUrl]
  );

  const handleInitialize = useCallback(
    (updatedOptions: CheckoutSdkOptions) => {
      setOptions(updatedOptions);
      resetTokenizeIdempotencyKey();
      resetAuthorizeIdempotencyKey();
    },
    [resetTokenizeIdempotencyKey, resetAuthorizeIdempotencyKey]
  );
  const handleEnable = useCallback(() => setIsEnabled(true), []);
  const handleDisable = useCallback(() => setIsEnabled(false), []);

  const handleHostSetErrorMessage = useCallback((errorMessage?: string): void => {
    if (typeof errorMessage !== 'string') return;

    setHostErrorMessage(errorMessage);
  }, []);

  const handlePaymentAction = async (paymentMethod: CheckoutPaymentMethodType) => {
    /** Handles ApplePay */
    if (paymentMethod === 'applepay') {
      const dropInOptions = options as CheckoutSdkDropInOptions;

      // generate redirectUrl
      const redirectUrl = generateAuthorizeFrameRedirectUrl({
        sourceOrigin,
        loaderVersion,
        sdkType,
        origin: window.origin,
      });

      const paymentData: AuthorizeAlternativePaymentInput = {
        flowId: dropInOptions?.flowId,
        currencyCode: dropInOptions?.currencyCode,
        amount: dropInOptions?.amount,
        orderId: dropInOptions?.orderId,
        intentId: dropInOptions?.intentId,
        perform3DSecure: {
          redirectUrl,
        },
      };

      proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.PaymentRequest, {
        paymentMethod: 'applepay',
        paymentData,
      });

      return;
    }

    /** Handles GooglePay */
    if (paymentMethod === 'googlepay') {
      await handleGooglePay();
      return;
    }

    /** Handles Card */
    if (paymentMethod === 'card') {
      await handlePayWithCard();
      return;
    }

    /**
     * Handles other APMs
     */
    await pay({
      handleTokenizeCard,
      handleAuthorizePayment,
      paymentMethodType: paymentMethod.toUpperCase() as PaymentMethod,
    });
  };

  const handlePaymentRequest = async (paymentMethod: CheckoutPaymentMethodType, triggerPaymentAction?: boolean) => {
    setActivePaymentMethod(paymentMethod);

    /** we want to displaye the google pay branded button here */
    if (isDropIn && !isCustomPayButton && paymentMethod === 'googlepay') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleGooglePay();
    }

    if (isDropIn && triggerPaymentAction) {
      await handlePaymentAction(paymentMethod);
      return;
    }

    /* prevent from triggering again when triggerPaymentAction is true
     * User first selects the payment method and we notify parent
     * they user clicks on pay button. - here we don't want to notify parent app again
     */
    if (!triggerPaymentAction) {
      proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.PaymentRequest, { paymentMethod });
    }
  };

  const handleTriggerCompletePayment = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handlePaymentAction(activePaymentMethod);
  }, [handlePayWithCard, activePaymentMethod]);

  const handleGenerateRedirectUrlForElements = useCallback(() => {
    /// generate redirectUrl
    const redirectUrl = generateAuthorizeFrameRedirectUrl({
      sourceOrigin,
      loaderVersion,
      sdkType,
      origin: window.origin,
    });

    proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.GenerateRedirectUrl, { redirectUrl });
  }, []);

  const handleSecureAuthenticationForElements = useCallback(
    (data: CheckHasAuthorizeErrorToHandleResult) => {
      if (data.supportsIframe && data.authUrl) {
        // setAuthorizationUrl will open AuthorizeFrame
        setAuthorizationUrl(data.authUrl);
      }
    },
    [setAuthorizationUrl]
  );

  useEffect(() => {
    proxyRef.current = new CheckoutSdkFrameToSdkProxy(
      {
        onInitialize: handleInitialize,
        onEnable: handleEnable,
        onDisable: handleDisable,
        onTokenisePaymentMethod: handleTokenizeCard,
        onAuthorizePayment: handleAuthorizePayment,
        onHostAuthComplete: handleHostAuthComplete,
        onSetErrorMessage: handleHostSetErrorMessage,
        onTriggerCompletePayment: handleTriggerCompletePayment,
        onCreateCustomer: handleCreateCustomer,
        onAuthorizeApplePayComplete: handleAuthorizeApplePayComplete,
        onGetGooglePayToken: handleGooglePay,
        onGenerateRedirectUrl: handleGenerateRedirectUrlForElements,
        onHandleSecureAuthentication: handleSecureAuthenticationForElements,
      },
      '#root'
    );

    const unsubscribe = proxyRef.current?.listen(host, sourceOrigin);

    return () => {
      unsubscribe();
    };
  }, [host, sourceOrigin]);

  useEffect(() => {
    proxyRef.current?.updateHandlers({
      onInitialize: handleInitialize,
      onEnable: handleEnable,
      onDisable: handleDisable,
      onTokenisePaymentMethod: handleTokenizeCard,
      onAuthorizePayment: handleAuthorizePayment,
      onHostAuthComplete: handleHostAuthComplete,
      onSetErrorMessage: handleHostSetErrorMessage,
      onTriggerCompletePayment: handleTriggerCompletePayment,
      onCreateCustomer: handleCreateCustomer,
      onAuthorizeApplePayComplete: handleAuthorizeApplePayComplete,
      onGetGooglePayToken: handleGooglePay,
      onGenerateRedirectUrl: handleGenerateRedirectUrlForElements,
      onHandleSecureAuthentication: handleSecureAuthenticationForElements,
    });
  }, [
    handleInitialize,
    handleEnable,
    handleDisable,
    handleTokenizeCard,
    handleHostSetErrorMessage,
    handleCreateCustomer,
    handleAuthorizeApplePayComplete,
    handleGenerateRedirectUrlForElements,
    handleSecureAuthenticationForElements,
  ]);

  const handleChange = useCallback(
    (event: CheckoutFormChange) => {
      setCheckoutState(event);
      resetTokenizeIdempotencyKey();
      resetAuthorizeIdempotencyKey();
      // remove failed state when user updates the card form
      if (paymentErrorMessage) {
        setPaymentErrorMessage(undefined);
      }
      if (hostErrorMessage) {
        setHostErrorMessage(undefined);
      }

      proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.Change, {
        isComplete: event.isComplete,
        value: {
          card: event.value.card as Card,
          billingAddress: event.value.billingAddress as BillingAddress,
        },
        errors: event.errors,
      });
    },
    [resetTokenizeIdempotencyKey, resetAuthorizeIdempotencyKey, setHostErrorMessage]
  );

  const PayButton = PAY_BUTTON_MAP[activePaymentMethod] || PAY_BUTTON_MAP.card;

  const canPayWithCard = (isEnabled && checkoutState?.isComplete) ?? false;

  const payButtonIsDisabled = (activePaymentMethod === 'card' && !canPayWithCard) || isLoading || !isEnabled;

  const apms = optimise?.length ? optimise : options?.alternativePaymentMethods;

  return (
    <>
      {options && !authorizationUrl && (
        <CheckoutContainer
          theme={options.theme}
          language={options.language}
          amount={(options as CheckoutSdkDropInOptions).amount}
          currency={(options as CheckoutSdkDropInOptions).currencyCode}
          isCustomPayButton={isCustomPayButton}
          isDropIn={isDropIn}
        >
          <CheckoutForm
            savedCards={paymentMethods}
            disabled={!isEnabled}
            hideBillingAddress={options.hideBillingAddress}
            allowSaveCard={options.allowSaveCard}
            isSavedCardCvcRequired={options.isSavedCardCvcRequired}
            countries={options.countries}
            alternativePaymentMethods={apms}
            onChange={handleChange}
            onPaymentRequest={handlePaymentRequest}
            supportedCardBrands={options.supportedCardBrands}
            loading={isLoading}
            activePaymentMethod={activePaymentMethod}
          />

          {isDropIn && !isCustomPayButton && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <PayButton handlePay={handleTriggerCompletePayment} disabled={payButtonIsDisabled} loading={isLoading} />
          )}

          <FailedState showFailedState={Boolean(hostErrorMessage)} label={hostErrorMessage} />

          {isDropIn && <FailedState showFailedState={Boolean(paymentErrorMessage)} label={paymentErrorMessage} />}
          {isDropIn && <SuccessState showSuccessState={showSuccessState} />}
        </CheckoutContainer>
      )}
    </>
  );
};

CheckoutSdk.propTypes = {};
CheckoutSdk.defaultProps = {};

export default CheckoutSdk;
