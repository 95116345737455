import { useEffect } from 'react';
import { AuthorizePostMessagePayload } from '@whenthen/checkout-sdk-core';
import { CheckoutSdkFrameEventType, CheckoutSdkFrameParam } from '@whenthen/checkout-sdk-hosted-core';
import { CheckoutSdkType } from '@whenthen/checkout-sdk-elements-core';
import { AuthorizeRedirectUrlParamKeys, isPopUpWindow, parseBoolean } from '../utils';
import { useValidatePayment } from './useValidatePayment';
import { useGlobalContext } from '../../globalContext';

const PAYMENT_ID_KEY = 'id';

/* Hook listens for 3ds and APM auth payload event and validate the payment */
export const useAuthorizeListener = (): void => {
  const { validatePayment } = useValidatePayment();
  const { proxyRef, setAuthorizationUrl } = useGlobalContext();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paymentId = params.get(PAYMENT_ID_KEY);
    const whenThenFrameUrl = params.get(AuthorizeRedirectUrlParamKeys.WhenThenFrameUrlKey);
    const redirectedFromAuth = params.get(AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey);
    const mainWindowOrigin = params.get(CheckoutSdkFrameParam.Referrer);
    const sdkType = params.get(CheckoutSdkFrameParam.SdkType) as CheckoutSdkType | undefined;
    const isDropIn = sdkType === CheckoutSdkType.DropIn;

    if (!parseBoolean(redirectedFromAuth) || !paymentId) return;

    /**
     * ELements will only get here through handleSecureAuthenticationForElements
     * So we need to pass the result back to loader
     */
    if (!isDropIn) {
      const noPaymentIdError = {
        errors: [{ type: 'authenticationFailed', message: 'No payment id returned from authentication.' }],
      };

      const payload = paymentId ? { paymentId } : noPaymentIdError;

      if (isPopUpWindow()) {
        /* eslint-disable @typescript-eslint/no-unsafe-call */
        /* eslint-disable @typescript-eslint/no-unsafe-member-access */
        window.opener.postMessage(
          {
            data: payload,
            eventType: CheckoutSdkFrameEventType.HandleSecureAuthentication,
          },
          mainWindowOrigin
        );
        return;
      }

      if (!whenThenFrameUrl) return;

      // iframe - window is within our app - see AuthorizeFrame
      window.parent.postMessage(
        {
          ...payload,
          key: AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey,
          isDropIn,
        },
        whenThenFrameUrl
      );

      return;
    }

    // every this else here is specific to drop-in
    const payload: AuthorizePostMessagePayload = {
      paymentId,
      key: AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey,
    };

    // check isPopUpWindow - if it's pop-up, window in on the merchant's site
    if (isPopUpWindow()) {
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      window.opener.postMessage(
        {
          data: payload,
          eventType: CheckoutSdkFrameEventType.CloseHostAuthWindow,
        },
        mainWindowOrigin
      );
      return;
    }

    if (!whenThenFrameUrl) return;

    // iframe - window is within our app - see AuthorizeFrame
    window.parent.postMessage(payload, whenThenFrameUrl);
  }, []);

  const handleAuthorizationListener = async (e: MessageEvent<AuthorizePostMessagePayload>): Promise<void> => {
    const { key, paymentId, isDropIn, errors } = e.data;
    if (key !== AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey || !paymentId) return;

    if (!isDropIn) {
      setAuthorizationUrl(undefined);

      proxyRef.current?.dispatchToHost(CheckoutSdkFrameEventType.HandleSecureAuthentication, {
        paymentId,
        ...(errors?.length && { errors }),
      });
    }

    await validatePayment(paymentId);
  };

  // listens for message from auth iframe -- see sdk-loader for pop-up listener
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('message', handleAuthorizationListener);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return () => window.removeEventListener('message', handleAuthorizationListener);
  }, [handleAuthorizationListener]);
};

export default useAuthorizeListener;
