import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { OptimiseResult, SdkIntent } from '@whenthen/sdk-intent';
import { ApiErrorType } from '../common/api';
import { OptimiseApiResult } from './optimise-api-result';

export const handleOptimise = async (apiKey: string, intentId: string): Promise<OptimiseApiResult> => {
  if (!apiKey) {
    throw new Error('Checkout SDK is not initialized.');
  }

  const intent = new SdkIntent({ apiKey, intentId });

  let response: OptimiseResult;
  try {
    response = await intent.optimise({
      intentId,
    });
  } catch (error) {
    const typedError = convertErrorToTypedError(ApiErrorType.Intent, error);
    return {
      errors: [typedError],
    };
  }

  const errors: TypedError[] | undefined = response.errors?.map((error) => ({
    type: ApiErrorType.Optimise,
    message: error.message,
  }));

  return {
    data: response.data,
    errors,
  };
};

export default handleOptimise;
