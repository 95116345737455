import { useCallback } from 'react';
import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { CustomerInput } from '@whenthen/sdk-vault';
import { CreateCustomerResult } from './create-customer-result';
import { createCustomer } from './create-customer';
import { ApiErrorType } from '../../common/api';

export interface UseCreateCustomerResult {
  createCustomer: (customerInput: CustomerInput) => Promise<CreateCustomerResult>;
}

export const useCreateCustomer = (apiKey?: string): UseCreateCustomerResult => {
  const handleCreateCustomer = useCallback(
    async (customerInput: CustomerInput): Promise<CreateCustomerResult> => {
      if (!apiKey) {
        const typedError: TypedError = { type: 'apiKeyRequired', message: 'Client token is required.' };

        return {
          errors: [typedError],
        };
      }

      try {
        const createCustomerResult = await createCustomer(apiKey, customerInput);

        return createCustomerResult;
      } catch (error) {
        const typedError = convertErrorToTypedError(ApiErrorType.Vault, error);

        return {
          errors: [typedError],
        };
      }
    },
    [apiKey]
  );

  return {
    createCustomer: handleCreateCustomer,
  };
};

export default useCreateCustomer;
