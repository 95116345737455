export enum ApiErrorType {
  Vault = 'vaultApiError',
  Intent = 'intentApiError',
  Authorize = 'authorizePaymentApiError',
  GetPayment = 'getPaymentApiError',
  SetupWallet = 'setupWalletApiError',
  Optimise = 'optimiseApiError',
}

export default ApiErrorType;
