import {
  convertErrorToTypedError,
  TypedError,
  AuthorizePostMessagePayload,
  CheckHasAuthorizeErrorToHandleResult,
} from '@whenthen/checkout-sdk-core';
import { AuthorizePaymentInput, AuthorizePaymentResult } from '@whenthen/sdk-authorize';
import { CustomerInput } from '@whenthen/sdk-vault';

import {
  CheckoutSdkHostEventMap,
  CheckoutSdkHostEventType,
  CheckoutSdkProxy,
  CheckoutSdkFrameEventMap,
  CheckoutSdkProxyMessagesOf,
  CheckoutSdkFrameEventType,
  CreateCustomerCheckoutResult,
} from '@whenthen/checkout-sdk-hosted-core';
import { SdkIntent, BaseOutput } from '@whenthen/sdk-intent';
import { CheckoutSdkOptions } from '@whenthen/checkout-sdk-elements-core';
import { ApiErrorType } from '../common/api';
import { TokenisePaymentMethodResult } from '../vault/tokenise-payment-method';

export interface CheckoutSdkFrameToSdkProxyHandlers {
  onInitialize: (options: CheckoutSdkOptions) => void;
  onTokenisePaymentMethod: (intent?: SdkIntent) => Promise<TokenisePaymentMethodResult>;
  onAuthorizePayment: (authorizeRequestInput: AuthorizePaymentInput) => Promise<AuthorizePaymentResult>;
  onEnable: () => void;
  onDisable: () => void;
  onHostAuthComplete: (payload: AuthorizePostMessagePayload) => Promise<void>;
  onSetErrorMessage: (errorMessage?: string) => void;
  onTriggerCompletePayment: () => void;
  onCreateCustomer: (customer: CustomerInput) => Promise<CreateCustomerCheckoutResult>;
  onAuthorizeApplePayComplete: (authorizeResult: AuthorizePaymentResult) => void;
  onGetGooglePayToken: () => Promise<void>;
  onGenerateRedirectUrl: () => void;
  onHandleSecureAuthentication: (data: CheckHasAuthorizeErrorToHandleResult) => void;
}
export class CheckoutSdkFrameToSdkProxy extends CheckoutSdkProxy<CheckoutSdkFrameEventMap, CheckoutSdkHostEventMap> {
  private handlers: CheckoutSdkFrameToSdkProxyHandlers;

  private readonly containerSelector: string;

  private apiKey: string | undefined;

  constructor(handlers: CheckoutSdkFrameToSdkProxyHandlers, containerSelector: string) {
    super();

    this.handlers = handlers;
    this.containerSelector = containerSelector;
  }

  updateHandlers(handlers: CheckoutSdkFrameToSdkProxyHandlers): void {
    this.handlers = handlers;
  }

  dispatchToHost<T extends keyof CheckoutSdkFrameEventMap>(eventType: T, data: CheckoutSdkFrameEventMap[T]): void {
    this.dispatchMessage(eventType, data);
  }

  protected onTargetMessage(message: CheckoutSdkProxyMessagesOf<CheckoutSdkHostEventMap>): void {
    switch (message.eventType) {
      case CheckoutSdkHostEventType.Initialize:
        this.onInitialize(message.data);
        break;
      case CheckoutSdkHostEventType.TokenisePaymentMethod:
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.onTokenisePaymentMethod(message.data);
        break;
      case CheckoutSdkHostEventType.AuthorizePayment:
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.onAuthorizePayment(message.data);
        break;
      case CheckoutSdkHostEventType.Enable:
        this.onEnable();
        break;
      case CheckoutSdkHostEventType.Disable:
        this.onDisable();
        break;
      case CheckoutSdkHostEventType.ClickOutside:
        this.onClickOutside();
        break;
      case CheckoutSdkHostEventType.HostAuthComplete:
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.onHostAuthComplete(message.data);
        break;
      case CheckoutSdkHostEventType.SetErrorMessage:
        this.onSetErrorMessage(message?.data);
        break;
      case CheckoutSdkHostEventType.CompletePayment:
        this.onTriggerCompletePayment();
        break;
      case CheckoutSdkHostEventType.CreateCustomer:
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.onCreateCustomer(message?.data);
        break;
      case CheckoutSdkHostEventType.AuthorizeApplePayComplete:
        this.onAuthorizeApplePayComplete(message?.data);
        break;
      case CheckoutSdkHostEventType.GetGooglePayToken:
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.onGetGooglePayToken();
        break;
      case CheckoutSdkHostEventType.GenerateRedirectUrl:
        this.onGenerateRedirectUrl();
        break;
      case CheckoutSdkHostEventType.HandleSecureAuthentication:
        this.onHandleSecureAuthentication(message?.data);
        break;
      default:
        throw new Error(`Unknown event type: "${message.eventType}".`);
    }
  }

  private onHandleSecureAuthentication(data: CheckHasAuthorizeErrorToHandleResult): void {
    this.handlers.onHandleSecureAuthentication(data);
  }

  private onGenerateRedirectUrl(): void {
    this.handlers.onGenerateRedirectUrl();
  }

  private async onGetGooglePayToken(): Promise<void> {
    await this.handlers.onGetGooglePayToken();
  }

  private onAuthorizeApplePayComplete(payload: AuthorizePaymentResult): void {
    this.handlers.onAuthorizeApplePayComplete(payload);
  }

  private async onCreateCustomer(customer: CustomerInput): Promise<void> {
    try {
      const result = await this.handlers.onCreateCustomer(customer);
      this.dispatchMessage(CheckoutSdkFrameEventType.CreateCustomer, result);
    } catch (error) {
      this.dispatchMessage(CheckoutSdkFrameEventType.Error, {
        errors: [convertErrorToTypedError(ApiErrorType.Vault, error)],
      });
    }
  }

  private onTriggerCompletePayment(): void {
    this.handlers.onTriggerCompletePayment();
  }

  private onSetErrorMessage(errorMessage: string | undefined): void {
    this.handlers.onSetErrorMessage(errorMessage);
  }

  private async onHostAuthComplete(payload: AuthorizePostMessagePayload): Promise<void> {
    await this.handlers.onHostAuthComplete(payload);
  }

  private onInitialize(options: CheckoutSdkOptions): void {
    this.apiKey = options.apiKey;

    this.handlers.onInitialize(options);

    this.dispatchMessage(CheckoutSdkFrameEventType.Initialize, undefined);

    const element = document.querySelector(this.containerSelector);

    if (element) {
      const resizeObserver = new ResizeObserver(([container]) => {
        this.dispatchMessage(CheckoutSdkFrameEventType.Resize, container.contentRect);
      });

      resizeObserver.observe(element);
    }
  }

  private async onTokenisePaymentMethod(intentId?: string): Promise<void> {
    let intent: SdkIntent | undefined;
    let intentErrors: TypedError[] = [];
    if (intentId) {
      try {
        const result = await this.getSdkIntent(intentId);
        intent = result.data;
        intentErrors = result.errors?.map((error) => convertErrorToTypedError(ApiErrorType.Intent, error)) ?? [];
      } catch (error) {
        intentErrors = [convertErrorToTypedError(ApiErrorType.Vault, error)];
      }
    }

    try {
      const result = await this.handlers.onTokenisePaymentMethod(intent);

      const resultWithIntentErrors: TokenisePaymentMethodResult = {
        ...result,
        errors: [...(result?.errors ?? []), ...intentErrors],
      };
      this.dispatchMessage(CheckoutSdkFrameEventType.TokenisePaymentMethod, resultWithIntentErrors);
    } catch (error) {
      this.dispatchMessage(CheckoutSdkFrameEventType.Error, {
        errors: [convertErrorToTypedError(ApiErrorType.Vault, error), ...intentErrors],
      });
    }
  }

  private async onAuthorizePayment(authorizeRequestInput: AuthorizePaymentInput): Promise<void> {
    try {
      const result = await this.handlers.onAuthorizePayment(authorizeRequestInput);
      this.dispatchMessage(CheckoutSdkFrameEventType.AuthorizePayment, result);
    } catch (error) {
      this.dispatchMessage(CheckoutSdkFrameEventType.Error, {
        errors: [convertErrorToTypedError(ApiErrorType.Vault, error)],
      });
    }
  }

  private onEnable(): void {
    this.handlers.onEnable();
  }

  private onDisable(): void {
    this.handlers.onDisable();
  }

  // eslint-disable-next-line class-methods-use-this
  private onClickOutside(): void {
    document.body.click();
  }

  private async getSdkIntent(intentId: string): Promise<BaseOutput<SdkIntent>> {
    if (!this.apiKey) {
      throw new Error('Checkout SDK is not initialized.');
    }

    const intent = new SdkIntent({ apiKey: this.apiKey, intentId });

    const { errors } = await intent.initialize();

    return {
      data: intent,
      errors,
    };
  }
}

export default CheckoutSdkFrameToSdkProxy;
