import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AlternativePaymentMethod } from '@whenthen/sdk-loader';
import { AlternativePaymentMethodType, CheckoutSdkDropInOptions } from '@whenthen/checkout-sdk-elements-core/dist/cjs';
import { handleOptimise } from '../../optimise';
import { useGlobalContext } from '../../globalContext';

export interface UseOptimiseResult {
  optimise: AlternativePaymentMethod<AlternativePaymentMethodType>[];
}

export const useOptimise = (): UseOptimiseResult => {
  const { options } = useGlobalContext();
  const [optimise, setOptimise] = useState<AlternativePaymentMethod[]>([]);

  useEffect(() => {
    const dropInOptions = options as CheckoutSdkDropInOptions;
    const optimisePromise = async () => {
      if (dropInOptions?.apiKey && dropInOptions?.intentId) {
        const { data } = await handleOptimise(dropInOptions.apiKey, dropInOptions.intentId);
        const parsedOptimiseData: AlternativePaymentMethod<AlternativePaymentMethodType>[] =
          data?.filter(Boolean).map((el) => ({ type: el?.toLowerCase?.() as AlternativePaymentMethodType })) ?? [];
        setOptimise(parsedOptimiseData ?? []);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    optimisePromise().catch();
  }, [options]);

  return {
    optimise,
  };
};

export default useOptimise;
