import { useCallback, useState } from 'react';
import { convertErrorToTypedError, DeepRequired, generateUuid, TypedError } from '@whenthen/checkout-sdk-core';
import { SdkIntent } from '@whenthen/sdk-intent';
import { BillingAddress, Card, CheckoutSdkOptions } from '@whenthen/checkout-sdk-elements-core';
import { TokenisePaymentMethodResult } from './tokenise-payment-method-result';
import { tokenisePaymentMethod } from './tokenise-payment-method';
import { updateIntentStatus, UpdateIntentStatusApiResult } from '../../intent';
import { ApiErrorType } from '../../common/api';
import { CheckoutFormState } from '../../checkout-sdk/checkout-form-state';

export interface UseTokenisePaymentMethodResult {
  tokenisePaymentMethod: (
    checkoutState: CheckoutFormState,
    options: CheckoutSdkOptions,
    intent?: SdkIntent
  ) => Promise<TokenisePaymentMethodResult>;

  resetIdempotencyKey: () => void;
}

export const useTokenisePaymentMethod = (apiKey?: string): UseTokenisePaymentMethodResult => {
  const [idempotencyKey, setIdempotencyKey] = useState<string | undefined>();

  const handleResetIdempotencyKey = useCallback(() => setIdempotencyKey(undefined), []);

  const handleTokenisePaymentMethod = useCallback(
    async (
      checkoutState: CheckoutFormState,
      options: CheckoutSdkOptions,
      intent?: SdkIntent
    ): Promise<TokenisePaymentMethodResult> => {
      if (!apiKey) {
        const typedError: TypedError = { type: 'apiKeyRequired', message: 'Client token is required.' };

        return {
          errors: [typedError],
        };
      }

      const { card, billingAddress, isSaveCard } = checkoutState.value;

      const isFormInvalid = !checkoutState.isComplete || !card || (!options.hideBillingAddress && !billingAddress);

      if (isFormInvalid) {
        const typedError: TypedError = { type: 'formInvalid', message: 'Form is invalid.' };

        return {
          errors: [typedError],
        };
      }

      // check if card already tokenised
      if (card.token) {
        let updateIntentStatusResult: UpdateIntentStatusApiResult | undefined;
        if (intent) {
          updateIntentStatusResult = await updateIntentStatus(intent);
        }

        return {
          token: card.token,
          customerId: options.customerId,
          cardCvc: options.isSavedCardCvcRequired ? card.cardCvc ?? '' : undefined, // CVC is required only for saved cards
          errors: updateIntentStatusResult?.errors,
        };
      }

      let tokenisePaymentMethodIdempotencyKey = idempotencyKey;
      if (!tokenisePaymentMethodIdempotencyKey) {
        tokenisePaymentMethodIdempotencyKey = generateUuid();
        setIdempotencyKey(tokenisePaymentMethodIdempotencyKey);
      }

      try {
        const tokenisePaymentMethodResult = await tokenisePaymentMethod(
          apiKey,
          card as DeepRequired<Card>,
          billingAddress as DeepRequired<BillingAddress>,
          tokenisePaymentMethodIdempotencyKey,
          options.customerId,
          isSaveCard
        );

        let updateIntentStatusResult: UpdateIntentStatusApiResult | undefined;
        if (!tokenisePaymentMethodResult.errors?.length && intent) {
          updateIntentStatusResult = await updateIntentStatus(intent);
        }

        const errors: TypedError[] | undefined =
          tokenisePaymentMethodResult.errors?.length || updateIntentStatusResult?.errors?.length
            ? [...(tokenisePaymentMethodResult.errors ?? []), ...(updateIntentStatusResult?.errors ?? [])]
            : undefined;

        return {
          token: tokenisePaymentMethodResult.token,
          customerId: tokenisePaymentMethodResult.customerId,
          errors,
        };
      } catch (error) {
        const typedError = convertErrorToTypedError(ApiErrorType.Vault, error);

        return {
          errors: [typedError],
        };
      }
    },
    [apiKey, idempotencyKey]
  );

  return {
    tokenisePaymentMethod: handleTokenisePaymentMethod,
    resetIdempotencyKey: handleResetIdempotencyKey,
  };
};

export default useTokenisePaymentMethod;
