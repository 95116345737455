import { createContext, useContext, MutableRefObject } from 'react';
import { CheckoutSdkOptions, CheckoutPaymentMethodType } from '@whenthen/checkout-sdk-elements-core';
import { AuthorizePaymentInput } from '@whenthen/sdk-authorize';
import { CheckoutSdkFrameToSdkProxy } from './proxy';
import { AuthorizePaymentResult } from './authorize-payment/authorize-payment-result';

export interface GlobalContextValues {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  authorizationUrl?: string;
  setAuthorizationUrl: (loading?: string) => void;
  options?: CheckoutSdkOptions;
  setOptions: (options?: CheckoutSdkOptions) => void;
  showSuccessState: boolean;
  setShowSuccessState: (showSuccessState: boolean) => void;
  paymentErrorMessage?: string;
  setPaymentErrorMessage: (errorMessage?: string) => void;
  activePaymentMethod: CheckoutPaymentMethodType;
  setActivePaymentMethod: (event: CheckoutPaymentMethodType) => void;

  resetAuthorizeIdempotencyKey: () => void;
  authorizePayment: (authorizePaymentInput: AuthorizePaymentInput) => Promise<AuthorizePaymentResult>;
  proxyRef: MutableRefObject<CheckoutSdkFrameToSdkProxy | undefined>;
  isCustomPayButton: boolean;
}

const GlobalContext = createContext<GlobalContextValues>({} as GlobalContextValues);
const GlobalContextProvider = GlobalContext.Provider;
const useGlobalContext = (): GlobalContextValues => useContext(GlobalContext);

export { GlobalContextProvider, useGlobalContext };
