import { TypedError } from '@whenthen/checkout-sdk-core';
import { CheckoutSdkFrameEventType } from '@whenthen/checkout-sdk-hosted-core';
import { useGlobalContext } from '../../globalContext';
import { ValidateAuthorizationResult } from '../validate-payment-result';
import { paymentSuccessStatuses, PaymentStatus } from '../../common';
import { AuthorizePaymentResult } from '../authorize-payment-result';

export interface UseCompletePaymentResult {
  completePayment: (result: ValidateAuthorizationResult) => void;
}
/* Hook to show success state or failed state after authorize is complete */
export const useCompletePayment = (): UseCompletePaymentResult => {
  const { proxyRef, setShowSuccessState, setPaymentErrorMessage, resetAuthorizeIdempotencyKey } = useGlobalContext();

  const completePayment = (result: ValidateAuthorizationResult): void => {
    const paymentStatus = result.data?.status;
    const succeeded = paymentSuccessStatuses.includes(paymentStatus as PaymentStatus);
    const declineCode = result.data?.paymentCore?.outcome?.declineCode;
    const declineMessage = declineCode || 'error.unknown';

    if (paymentStatus) {
      resetAuthorizeIdempotencyKey();
    }

    if (succeeded) {
      // show success state
      setShowSuccessState(true);
    } else {
      setPaymentErrorMessage(declineMessage);
    }

    // notify main app - PaymentComplete event
    proxyRef.current?.dispatchToHost(
      CheckoutSdkFrameEventType.PaymentComplete,
      result as AuthorizePaymentResult<TypedError[]>
    );
  };

  return {
    completePayment,
  };
};

export default useCompletePayment;
