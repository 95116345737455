import { useEffect, useState, useCallback, useRef } from 'react';
import { convertErrorToTypedError, TypedError } from '@whenthen/checkout-sdk-core';
import { PaymentMethod } from '@whenthen/sdk-vault';
import { getPaymentMethods } from './get-payment-methods';
import { ApiErrorType } from '../../common/api';

export interface UsePaymentMethodsProps {
  apiKey?: string;
  customerId?: string;
}

export interface UsePaymentMethodsResult {
  isLoading: boolean;
  errors: TypedError[];
  paymentMethods: PaymentMethod[];
}

export const usePaymentMethods = ({ apiKey, customerId }: UsePaymentMethodsProps): UsePaymentMethodsResult => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<TypedError[]>([]);

  const isCalledRef = useRef(false);

  const fetchPaymentMethods = useCallback(
    async (_apiKey: string, _customerId: string) => {
      setIsLoading(true);
      try {
        const { paymentMethods: customerCards, errors: getPaymentMethodsErrors } = await getPaymentMethods(
          _apiKey,
          _customerId
        );

        if (getPaymentMethodsErrors?.length) {
          setErrors(getPaymentMethodsErrors);
          return;
        }

        setPaymentMethods(customerCards ?? []);
      } catch (error) {
        const typedError = convertErrorToTypedError(ApiErrorType.Vault, error);
        setErrors([typedError]);
      } finally {
        setIsLoading(false);
      }
    },
    [apiKey, customerId]
  );

  useEffect(() => {
    if (!apiKey) {
      const typedError: TypedError = { type: 'apiKeyRequired', message: 'Client token is required.' };
      setErrors([typedError]);
      return;
    }

    if (!customerId) {
      const typedError: TypedError = { type: 'customerIdRequired', message: 'Customer ID is required.' };
      setErrors([typedError]);
      return;
    }

    if (!isCalledRef.current && apiKey && customerId) {
      isCalledRef.current = true;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchPaymentMethods(apiKey, customerId);
    }
  }, [fetchPaymentMethods, apiKey, customerId]);

  return {
    paymentMethods,
    isLoading,
    errors,
  };
};
